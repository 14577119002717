/*
* PDUI LINK TOOLS
*
* 1. Standard link generator
* 2. On colored background link generator (UI colors only)
* 3. On dark background link generator
* 3. On light background link generator
* 5. Improved link underline
*/

// 1. STANDARD LINK GENERATOR
@mixin std-link($color: $font-color-primary-on-white) {
  color: $color;
  fill: currentColor;
  text-decoration: underline;
  cursor: pointer;

  &:link,
  &:visited {
    color: $color; // Override ignite-tokens.css
  }

  &:link,
  &:hover,
  &:active,
  &:visited {
    font-size: inherit; // Override ignite-tokens.css
  }

  &:hover,
  &:focus,
  &:active {
    @include link-focus-indicator($indicator-color: var(--theme-background-color));
  }
}

// 2. ON COLORED BACKGROUND LINK GENERATOR (UI COLORS ONLY)
@mixin on-color-link($background-color) {
  @if
    $background-color != 'primary' and
    $background-color != 'danger' and
    $background-color != 'warning' and
    $background-color != 'success' and
    $background-color != 'info' {
    @error 'Invalid #{$background-color} value';
  }

  @if $background-color == 'primary' {
    color: $font-color-on-primary;
  } @else {
    color: $white;
  }

  &:hover,
  &:focus,
  &:active {
    @if $background-color == 'primary' {
      color: $font-color-primary-on-white;
    } @else {
      color: color($background-color, dark);
    }
    background-color: $white;
  }
}

// 3. ON DARK COLOR LINK GENERATOR
@mixin on-dark-link {
  color: $white;

  &:hover,
  &:focus,
  &:active {
    color: $dark-font-color;
    background-color: $white;
  }
}

// 4. ON LIGHT COLOR LINK GENERATOR
@mixin on-light-link {
  color: $dark-font-color;

  &:hover,
  &:focus,
  &:active {
    color: $white;
    background-color: $dark-font-color;
  }
}

/*
  5. IMPROVED LINK UNDERLINE
  This mixin improves the visual apparence of link underlines
  in some browsers (e.g. Firefox, IE).

  By using it, we make sure that the underline cuts through the descenders
  of letterforms like j or g across browsers, improving readability and visual style.

  It is necessary to know the background color of the text to use this mixin.

  By default, links have text-decoration: underline which acts as a fallback when
  this mixin is not used.

  Source: https://css-tricks.com/styling-underlines-web/
*/

@mixin improved-link-underline($background-color) {
  &:not(:hover) {
    &:not(:focus) {
      &:not(:active) {
        text-decoration: none;
        text-shadow:
          0.05em 0 0 $background-color,
          -0.05em 0 0 $background-color,
          0 0.05em 0 $background-color,
          0 -0.05em 0 $background-color,
          0.1em 0 0 $background-color,
          -0.1em 0 0 $background-color,
          0 0.1em 0 $background-color,
          0 -0.1em 0 $background-color;
        background-image: linear-gradient(to right, currentColor 0%, currentColor 100%);
        background-repeat: repeat-x;
        background-position: bottom 0.05em center;
        background-size: 100% 0.0625em;
      }
    }
  }
}
