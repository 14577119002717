// BEM SASS Helpers

/*
  // USE

  @include block('menu') {
    border: 1px solid #ccc;
    padding: 8px;

    @include element('item-list') {
      margin: 0;
    padding: 0;
      list-style: none;
    }

    @include mixie('button') {
      border-radius: 2px;
    }

    @include modifier('horizontal') {
      @include element('item', 'badge') {
        display: inline-block;

        @include modifier('active') {
          color: red;
        }
      }
    }
  }
*/

$bem-element-separator: '__' !default;
$bem-modifier-separator: '--' !default;

@function get-block($selector) {
  $index: null;

  // cast selector as a string
  $string: get-last-item($selector);

  // remove first '.' character
  $string: str_slice($string, 2, str_length($string));
  $index: str_index($string, $bem-element-separator);

  @if $index {
    $string: str_slice($string, 1, $index - 1);
  }

  $index: str_index($string, $bem-modifier-separator);

  @if $index {
    $string: str_slice($string, 1, $index - 1);
  }

  $index: str_index($string, '.');

  @if $index {
    $string: str_slice($string, 1, $index - 1);
  }

  $index: str_index($string, '.');

  // make sure we don't have any space
  $string: str-replace($string, ' ', '');

  // give back the sliced '.'
  $string: '.#{$string}';

  @return $string;
}

@function get-last-item($selector) {
  $index: null;

  // cast selector as a string
  $string: unquote('#{$selector}');

  // find next ' .' occurence
  $index: str_index($string, ' .');


  // if there is a ' ' character, we are not done
  @if $index {
    // remove everything before ' ' character
    $string: str_slice($string, $index + 1, str_length($string));

    // recursively call this function on the result
    @return get-last-item($string);
  }

  // find ':not([data-version])' occurence
  $index: str_index($string, ':not([data-version])');

  // if there is a ':not([data-version])' selector, remove it from the name of the block
  @if $index {
    $string: str_slice($string, 1, $index - 1);
  }

  // find '[data-version="n"]' occurence
  $index: str_index($string, '[data-version=');

  // if there is a '[data-version="n"]' selector, remove it from the name of the block
  @if $index {
    $string: str_slice($string, 1, $index - 1);
  }

  // make sure we don't have any space
  $string: str-replace($string, ' ', '');

  @return $string;
}

@mixin block($name, $version: false) {
  @if $version {
    @if type-of($version) != number or round($version) != $version or $version <= 0 {
      @error 'Property #{$version} must be an integer of `1` or greater.';
    }
    .#{$name} {
      @if $version == 1 {
        &:not([data-version]) {
          @content;
        }
      } @else {
        &[data-version='#{$version}'] {
          @content;
        }
      }
    }
  } @else {
    .#{$name}{
      @content;
    }
  }
}

@mixin element($names...) {
  $block: get-block(&);

  $selector: '';

  @each $name in $names {
    $selector: '#{$selector}#{$block}#{$bem-element-separator}#{$name}, ';
  }

  #{$selector} {
    @content;
  }
}

@mixin modifier($name) {
  $block-or-element: get-last-item(&);

  &#{$block-or-element}#{$bem-modifier-separator}#{$name} {
    @content;
  }
}

@mixin mixie($names) {
  @include element($names) {
    @content;
  }
}

/*
  The purpose of the mixin is to allow for a style to be applied on an element
  only if a set of modifier classes is present on that element
  and not just one particualar modifier class

  This mixin can only be used inside a @mixin modifier {…} as in :

  @include block('paper')
    height:100px;
    width: 20px;

    @include modifier('long') {
      height:200px;

      @include and-modifier('thin') {
        padding:2px;

        @include and-modifier('white') {
          background-color:white;
        }
      }
    }
  }

  which will compile into :

  .paper {
    height:100px;
    width: 20px;
  }
  .paper.paper--long {
    height:200px;
  }
  .paper.paper--long.paper--thin {
    padding:2px;
  }
  .paper.paper--long.paper--thin.paper--white {
    background-color:white;
  }
*/
@mixin and-modifier($name){

  // getting selector at current level and reversing it in order to use str-index
  $reversed-parent-selector: str-reverse(get-last-item(&));
  $previous-modifier-separator-index: str-index($reversed-parent-selector,$bem-modifier-separator);

  @if ($previous-modifier-separator-index){
    $previous-modifier-name: str-slice($reversed-parent-selector, 1, $previous-modifier-separator-index - 1);
    $reversed-parent-selector: str-replace($reversed-parent-selector,$previous-modifier-name,'');
    $reversed-parent-selector: str-replace($reversed-parent-selector,$bem-modifier-separator,'');
  }

  $reversed-modifier-prfx-start: str-index($reversed-parent-selector,'.');
  $reversed-modifier-prfx: str-slice($reversed-parent-selector, 1, $reversed-modifier-prfx-start - 1);

  $modifier-prfx: str-reverse($reversed-modifier-prfx);

  &.#{$modifier-prfx}#{$bem-modifier-separator}#{$name} {
    @content;
  }
}
