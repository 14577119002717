// IGNITE TOKENS

@import '@ignite/tokens/scss/ignite-tokens';

// THEME

@import '@ukgepic/hrsd-pdui-theme/src/settings/theme';

// SETTINGS

@import '@ukgepic/hrsd-pdui-theme/src/settings/color';
@import '@ukgepic/hrsd-pdui-theme/src/settings/badge';
@import 'settings/blocks';
@import 'settings/breakpoints-and-layouts';
@import 'settings/button';
@import 'settings/form';
@import 'settings/radius';
@import 'settings/rem';
@import 'settings/target';
@import 'settings/typography';
@import 'settings/z-index';

// TOOLS

@import 'tools/aspect-ratio';
@import 'tools/animation';
@import 'tools/bemify';
@import 'tools/bubble';
@import 'tools/button';
@import 'tools/card';
@import 'tools/checkable-input';
@import 'tools/disabled';
@import 'tools/drop';
@import 'tools/form';
@import 'tools/link';
@import 'tools/seamless-button';
@import 'tools/shadow';
@import 'tools/state-indicators';
@import 'tools/string';
@import 'tools/transform';
@import 'tools/typography-helpers';
@import 'tools/typography';
@import 'tools/vertical-align-center';
@import 'tools/visibility';

// LIBRARIES
@import 'sass-rem/rem';

// UKG BRAND OVERRIDES

// 1. Add UKG background gradient to header

@include block('header') {
  &:not(.header--dark) {
    background-color: $ukg-brand;
    background-image: linear-gradient(90deg, $ukg-brand 0%, #087c79 60%, $ukg-brand 100%);
    background-position: center center;
    background-size: 1366px 100%;
    background-repeat: no-repeat;

    .header__sidebar-notifier {
      @include rem(box-shadow, 0 0 0 1px $ukg-brand);
    }

    a {
      &.header__surtitle {
        @include improved-link-underline(transparent);
      }

      &:hover,
      &:focus,
      &:active {
        color: $ukg-brand;
      }
    }
  }
}

// 2. Make selected tab indicator green

@include block('tabs') {
  @include element('tab') {
    &--selected, // v1 deprecated modifier
    &[aria-current], // aria-current="page" in tab as links
    &[aria-selected='true'] {
      &::after {
        background-color: $ukg-brand;
      }
    }
  }
}
