@keyframes spin-clockwise {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes shake-horizontal {
  0% {
    transform: translateX(0);
  }

  20% {
    transform: translateX(-5px);
  }

  40% {
    transform: translateX(5px);
  }

  60% {
    transform: translateX(-5px);
  }

  80% {
    transform: translateX(5px);
  }

  100% {
    transform: translateX(0);
  }
}

@keyframes opacity-loop {
  0% {
    opacity: 0.4;
  }

  50% {
    opacity: 0.6;
  }

  100% {
    opacity: 0.4;
  }
}
